<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Tuyến xe Bus </a></li>
    </ol>
    <div class="container-fluid">
      <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <router-link to="/admin/bus/add" class="btn btn-sm btn-primary">
                <i class="fas fa-plus"></i>
                Thêm mới Tuyến xe Bus
              </router-link>
            </div>
          </div>
          <div class="col-sm-1" v-if="is_loading">
              <MyLoading></MyLoading>
          </div>
         <div class="col-sm-1" v-else>
            <div class="form-group">

              <xlsx-workbook>
                <xlsx-sheet
                  
                  v-for="sheet in sheets"
                  :key="sheet.name"
                  :sheet-name="sheet.name"
                  :collection="sheet.data"
                />
                <xlsx-download :filename="sheetName">
                  <button  class="btn btn-sm btn-success">
                     <i class="fas fa-file-export"></i>
                Export 
                 
                  </button>
                </xlsx-download>
                
              </xlsx-workbook>
                


               
            </div>
          </div>

          <div class="col-sm-1">
            <div class="form-group">

              
                


               <router-link to="/admin/bus/import" class="btn btn-sm btn-success ">
                 <i class="fas fa-file-import"></i>
                Import  
              </router-link>
            </div>
          </div>
    </div>
    <div class="row">
          <div class="col-sm-10">
            <div class="form-group">
              <div v-if="is_api" >
                
                   <MyLoading ></MyLoading>

                    Số lượng thêm mới vào: {{api_counter}}
              </div>
             
              <button v-else class="btn btn-sm btn-danger" @click="RunAPI()">
                <i class="fas fa-plus"></i>
                  Import Form API
              </button>


               <button style="display: none;"  class="btn btn-sm btn-danger ml-5" @click="RunDelete()">
                <i class="fas fa-remove"></i>
                    Delete All Data
              </button>
            </div>
          </div>
    </div>

      <div>
        <form method="get"  @submit.prevent="onSearch"  id="my_search">
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
                v-model="formSearch.name"
                autocomplete="off"
                placeholder="Nhập tên..."

                
              />

              <button class="btn btn-sm btn-warning mt-3" type="submit">
                <i class="fa fa-search-plus"></i> TÌM KIẾM
              </button>
            </div>
             
          </div>
        </form>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading ">
              <MyLoadTheme color="#e6f3fd" highlight="#eef6fd">
                <MyLoading width="40px" height="40"></MyLoading>
              </MyLoadTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Tuyến xe Bus : ( {{ page_size }} )
            </div>
            <div class="card-block">
              <MyLoadTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                       <th> Tên  </th>
                      <th> Lịch trình   </th>
                      <th> Thời gian  </th>
                  
                      <th>Tuỳ chọn</th>

                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 20" :key="k">
                      <td v-for="kk in 4" :key="kk">
                        <MyLoading height="50px"></MyLoading>
                      </td>
                      
                    </tr>
                  </tbody>
                  <tbody v-else>




                    <tr
                      v-for="(value, key) in paginationslice(mydata, 20, page)"
                      :Key="key"
                    >
                       <td>
                      
                          {{value.name}}
                          
                      </td>
                      
                      <td >
                       {{ value.schedule  }} 
                      </td>
                      
                       <td>
                       {{ value.timer  }} 
                      </td>
                       
                      <td>
                        <router-link
                          class="btn btn-sm btn-success"
                          v-bind:to="'/admin/bus/edit/' + value.id"
                        >
                          <i class="fa fa-edit"></i>
                          Sửa
                        </router-link>
                        <div
                          class="btn btn-sm btn-danger "
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-remove"></i> Xoá
                        </div>

                      
                      </td>

                      
                    </tr>
                  </tbody>
                </table>

               <nav v-if="mydata != null && mydata.length > 20">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 20)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </MyLoadTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>

import VuePagination from "vuejs-paginate";
import axios from "axios";

import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
export default {
  
  data() {
    return {
      is_loading: true,
      is_api:false,
      api_data:null,
      api_counter:0,
      form: {},
      mydata: null,
      main:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:""
      },
      language: window.my_header.language,
      role:2,

      sheetName:"Tuyen_xe_Bus_"+(new Date()).getDate().toString()+(new Date()).getFullYear().toString()+".xlsx",
      
      sheets: [{ name: "Tuyen xe Bus", data: [  {"BUITIN":"TEST"} ] }],

      
    };
  },
  components: {
   
    VuePagination,
    XlsxWorkbook, XlsxSheet, XlsxDownload
  },
  created(){  
      let isLogin= this.$session.get("isLogin");
      this.isLogin=isLogin.permistions.split(",");

      let Customer= this.$session.get("Customer");
      this.role=Customer.isRole;
  },
  mounted() {

   


   let p2=  axios
      .get(window.my_api + "api/bus/get-buss", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata  = this.main= res.data.data;
          this.page_size=this.mydata.length;
            
          if(this.$route.query.name !=undefined){
              this.formSearch.name=this.$route.query.name;
              this.mydata=this.main.filter(n => n.name.toUpperCase().search(this.$route.query.name.toUpperCase()) > -1 );
          }
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p2])
             .then( ()=>{

                  this.sheets[0]['data']=[];

                  let DataSheets=[];

                  for (var i = this.mydata.length - 1; i >= 0; i--) {
                    
                    DataSheets.push({
                           "Tên":this.mydata[i].name,
                          'Tên tuyến xe': this.mydata[i].schedule,
                       
                          'Thời gian': this.mydata[i].timer == undefined ? "" :this.mydata[i].timer  ,
                        

                    });

                  } 
  
                  this.sheets[0]['data']=DataSheets;

                this.is_loading = false;
             });
  },
  methods: {
    onSearch:function(){

      
        

          this.$router.push({query:{'name':this.formSearch.name}});

          this.mydata=this.main.filter(n => n.schedule.toUpperCase().search(this.formSearch.name.toUpperCase()) > -1);
    },
     changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {

      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },


    
    removeElement(id) {
    if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/bus/remove-bus", {
                data: { busIds: [id] },
                headers: window.my_header
              })
              
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");

                   this.mydata = this.main.filter((el) => el.id != this.id_load);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
      
    },
    RunAPI(){
        this.is_api=true;

        let id_data=[];
        for (var i = this.main.length - 1; i >= 0; i--) {
            id_data.push(this.main[i].name) ;
        }

        let p1=axios.get("http://apiservices.ebms.vn/api/routes/?apikey=P4iw3GVQ9kbYfWOR95ZcmnHT00jKKa4z")
          .then( (res)=>{
           
              if(res.status==200){
                  this.api_data=res.data;
              }else{
                alert("API không hoạt động. Vui lòng kiểm tra lại.");
                this.is_api=false;
              }
          });


        Promise.all([p1]).then(()=>{
            let array_process=[];
            for (var i = this.api_data.length - 1; i >= 0; i--) {
              
              if(id_data.indexOf(this.api_data[i].code)== -1 ){
                    console.log(this.api_data[i]);

                   let formData = new FormData();  
                   formData.append("name",this.api_data[i].code);  

                   formData.append("schedule",this.api_data[i].info.name);  
                   formData.append("timer",this.api_data[i].metadata[4].value==null?"":this.api_data[i].metadata[4].value);  
                   formData.append("node", JSON.stringify( this.api_data[i] ) );  

                   formData.append("orderby",i); 
                   formData.append("status",1);  

                   formData.append("schedule_en",this.api_data[i].info.name);  
                   formData.append("timer_en",this.api_data[i].metadata[4].value==null?"":this.api_data[i].metadata[4].value);  
                   formData.append("node_en", JSON.stringify( this.api_data[i] ) );   
                   

                   let t= axios
                      .post(window.my_api + "api/bus/create-or-update-bus", formData, {
                        headers: window.my_header,
                      })
                      .then((res) => {

                        if (res.status == 200) {
                            
                            this.api_counter=this.api_counter+1;

                        }
                       
                      });
                    array_process.push(t);


                  

                } 
            }

            if(array_process.length>0){
                Promise.all(array_process).then(()=>{
                   this.is_api=false;
                });
            }else{
                this.is_api=false;
            }

            
            

        });

    },
    RunDelete(){
            if(confirm("Bạn muốn xoá tất cả dữ liệu tuyến xe Bus?")){
                  let id_data=[];
                  for (var i = this.main.length - 1; i >= 0; i--) {
                      id_data.push(this.main[i].id) ;
                  }

                    axios
                        .delete(window.my_api + "api/bus/remove-bus", {
                          data: { busIds: id_data },
                          headers: window.my_header
                        })
                        
                        .then((res) => {
                          if (res.status == 200) {
                            alert("Xoá thành công.");

                           
                          } else {
                            alert(
                              "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                            );
                          }
                         
                        

                        })
          }
        }
  },
};
</script>








